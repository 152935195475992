import initSqlJs from "sql.js";
import sqlWasm from "../../node_modules/sql.js/dist/sql-wasm.wasm";
import { isLeapYear } from "./utils";

export async function loadGAPEng() {
  // const SQL = await initSqlJs();
  // Open the database
  // const filebuffer = fs.readFileSync("gap.db");
  // const db = new SQL.Database(filebuffer);

  const now = new Date(); // 현재 날짜 및 시간
  const year = now.getFullYear(); // 현재 연도

  const januaryFirst = new Date(year, 0, 1); // 해당 연도의 1월 1일
  const timeDifference = now - januaryFirst; // 밀리초 단위의 시간 차이

  let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // 일 단위로 계산
  if (isLeapYear(year) && now.getMonth() + 1 >= 3) {
    // 윤년일때는 2월 29일과 3월 1일을 같게 만듭니다..
    dayDifference -= 1;
  }

  const dataPromise = fetch("/db/gap_eng.db?v=1").then((res) =>
    res.arrayBuffer()
  );
  const sqlPromise = initSqlJs({ locateFile: () => sqlWasm });
  const [SQL, buf] = await Promise.all([sqlPromise, dataPromise]);
  const db = new SQL.Database(new Uint8Array(buf));

  // Execute the SQL query to retrieve the metadata for the given day
  const query = `SELECT * FROM gap WHERE day=${dayDifference}`;
  const query_results = db.exec(query)[0].values;

  const results = {
    count: query_results[0][1],
    c1: query_results[0][2],
    c2: query_results[0][3],
    c3: query_results[0][4],
    c4: query_results[0][5],
    c5: query_results[0][6],
    c6: query_results[0][7],
    c7: query_results[0][8],
  };

  // Access the metadata values

  let todaygap = [];
  let index = ["c1", "c2", "c3", "c4", "c5", "c6", "c7"];

  let book = [];
  let chapter = [];
  let init = [];
  let end = [];
  let verses = {};

  let bible_book = [
    "",
    "Genesis",
    "Exodus",
    "Leviticus",
    "Numbers",
    "Deuteronomy",
    "Joshua",
    "Judges",
    "Ruth",
    "1 Samuel",
    "2 Samuel",
    "1 Kings",
    "2 Kings",
    "1 Chronicles",
    "2 Chronicles",
    "Ezra",
    "Nehemiah",
    "Esther",
    "Job",
    "Psalms",
    "Proverbs",
    "Ecclesiastes",
    "Song of Solomon",
    "Isaiah",
    "Jeremiah",
    "Lamentations",
    "Ezekiel",
    "Daniel",
    "Hosea",
    "Joel",
    "Amos",
    "Obadiah",
    "Jonah",
    "Micah",
    "Nahum",
    "Habakkuk",
    "Zephaniah",
    "Haggai",
    "Zechariah",
    "Malachi",
    "Matthew",
    "Mark",
    "Luke",
    "John",
    "Acts",
    "Romans",
    "1 Corinthians",
    "2 Corinthians",
    "Galatians",
    "Ephesians",
    "Philippians",
    "Colossians",
    "1 Thessalonians",
    "2 Thessalonians",
    "1 Timothy",
    "2 Timothy",
    "Titus",
    "Philemon",
    "Hebrews",
    "James",
    "1 Peter",
    "2 Peter",
    "1 John",
    "2 John",
    "3 John",
    "Jude",
    "Revelation",
  ];

  for (let i = 0; i < results.count; i++) {
    todaygap[i] = results[index[i]];
    end[i] = 0;
  }

  // [책];[장]:[절시작]-[절끝]   ===> 절 범위가 있는 경우
  // [책];[장]                ===> 한 장 전체가 범위인 경우
  for (let j = 0; j < results.count; j++) {
    book[j] = todaygap[j].split(";")[0];
    chapter[j] = todaygap[j].split(";")[1].split(":")[0];
    // chapter[j] = todaygap[j].split(":")[0];

    // 장의 부분만 범위에 해당하는 경우
    if (todaygap[j].includes(":")) {
      init[j] = todaygap[j].split(":")[1].split("-")[0];
      end[j] = todaygap[j].split(":")[1].split("-")[1];
    }
    // 한 장 전체가 범위인 경우
    else {
      init[j] = 1;
    }

    let index_str = bible_book[book[j]] + " " + chapter[j] + " ";
    if (todaygap[j].includes("-")) {
      index_str += init[j] + "-" + end[j];
      //console.log(book[j], chapter[j], init[j], end[j])
      //console.log( `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse>=${init[j]} and verse<=${end[j]} order by verse asc`)
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse>=${init[j]} and verse<=${end[j]} order by verse asc`
      );
      // console.log(bible_query_result[0]);
      verses[index_str] = {};
      let k = init[j];
      for (let row of bible_query_result[0].values) {
        verses[index_str][k] = row[3];
        k++;
      }
    } else if (todaygap[j].includes(":")) {
      index_str += init[j];
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter=${chapter[j]} and verse=${init[j]}`
      );

      verses[index_str] = {};
      let k = init[j];
      while (bible_query_result[0].values[k - 1]) {
        verses[index_str][k] = bible_query_result[0].values[k - 1][3];
        k++;
      }
    } else {
      let bible_query_result = db.exec(
        `SELECT * FROM bible WHERE book=${book[j]} and chapter='${chapter[j]}';`
      );

      verses[index_str] = {};
      let k = init[j];
      for (let row of bible_query_result[0].values) {
        verses[index_str][k] = row[3];
        k++;
      }
    }
  }
  return verses;
}
